const $ = jQuery;
import 'lity';
import './polyfill/closest';
import Player from '@vimeo/player';

// Import additional libraries here

let viewportWidth = window.innerWidth || document.documentElement.clientWidth;

function is_touch_device() {
    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    var mq = function(query) {
        return window.matchMedia(query).matches;
    }

    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
        return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
}

const toggle = document.getElementsByClassName('toggle');
const menuContainer = document.querySelectorAll('.menu-main-menu-container');
const menuItemsWithChildren = document.querySelectorAll('.menu-item-has-children');
// const menu = document.querySelectorAll('#menu-main-menu');

window.addEventListener('load', function () {
    for (let i = 0; i < toggle.length; i++) {
        toggle[i].addEventListener('click', (event) => {
            if (viewportWidth < 992) {
                event.target.classList.toggle('on');
                for (let c = 0; c < menuContainer.length; c++) {

                    if ( menuContainer[i].classList.contains('open') ) {
                        // closing
                        menuContainer[i].removeAttribute('style');
                    } else {
                        // opening
                        setTimeout(() => {
                            menuContainer[i].setAttribute('style', 'height: auto; min-height: 100%;');
                        }, 1000);
                    }

                    menuContainer[i].classList.toggle('open');
                }
            }
        });
    }

    for (let i = 0; i < menuItemsWithChildren.length; i++) {
        menuItemsWithChildren[i].addEventListener('click', (event) => {
            if (viewportWidth < 992 || is_touch_device()) {
                const currentElement = menuItemsWithChildren[i];

                if (!currentElement.classList.contains('show')) {
                    event.preventDefault();
                    const subMenu = currentElement.querySelector('.sub-menu');
                    openSubMenu(subMenu);
                    currentElement.classList.add('show');
                }
            }
        });
    }

}, false);

//close menu
$(document).ready(function() {
    $('.menu-item-has-children').on('click', function() {
        if ($(this).hasClass('show')) {
        } else {
            $(this).siblings('.show').find('.sub-menu').css('height', '0px');
            $(this).siblings('.show').removeClass('show');
        }
    })

    $('.skip-links').focusin(function () {
        $(this).removeClass('hidden');
    });

    $('.skip-links').focusout(function () {
        $(this).addClass('hidden');
    });
});

window.addEventListener('resize', function () {

    viewportWidth = window.innerWidth || document.documentElement.clientWidth;

}, false);

window.addEventListener('resize', function () {

    viewportWidth = window.innerWidth || document.documentElement.clientWidth;

}, false);

document.addEventListener('DOMContentLoaded', function () {

    let iframe, player, playButton;

    if (window.location.pathname == "/") {

        // console.log('on homepage');

        iframe = document.getElementById('homepage-video');
        player = new Player(iframe);
        playButton = document.getElementById('play-button');
        // watchLink = document.getElementById('watch-video');
        // lityClose = document.getElementsByClassName('lity-close');

        playButton.addEventListener('click', (event) => {
            event.preventDefault();

            if (playButton.classList.contains('playing')) {
                player.pause();
            } else {
                player.play();
            }

            playButton.classList.toggle('playing');
        });

    }

    if (viewportWidth > 991) {
        // console.log('above 991');
        player.play();
    }

});

$(document).on('lity:open', function() {
    let $playButton = $('#play-button');

    $playButton.css('display', 'none');
});

$(document).on('lity:close', function() {
    let $playButton = $('#play-button');

    $playButton.css('display', 'block');
});


function openSubMenu(element) {
    const panelHeight = element.scrollHeight;

    element.setAttribute('style', `
            height: ${panelHeight}px;
        `);
}

//Change header on scroll
window.onscroll = () => {
    const header = document.getElementsByClassName('header')[0];
    let scrollTop = window.pageYOffset;


    if(window.scrollY <= 150 || scrollTop <= 150) {
        header.classList.remove('cover');
    } else {
        header.classList.add('cover');
    }
};

function isAppleDevice() {
    return (
        (navigator.userAgent.toLowerCase().indexOf("ipad") > -1) ||
        (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) ||
        (navigator.userAgent.toLowerCase().indexOf("ipod") > -1)
    );
}
if (isAppleDevice()) {
    $('body').addClass('is-apple')
}